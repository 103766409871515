import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import Select from "react-select"
import DropdownSearch from "../components/DropdownSearch";
import ReactDatePicker from "react-datepicker";
import { getTeacherList, create, edit } from "../api/classroom.api";
import { useUserContext } from '../contexts/UserContext';
import { getClassroomByID } from "../api/classsection.api";
import { showCreateDialog, showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";
import LoadingComponent from "../components/Loading";

const TeacherClassManageAction = () => {
  const { user } = useUserContext();
  const [method, setMethod] = useState("");
  const [ClassroomType, setClassroomType] = useState([]);
  const [classroomData, setClassroomData] = useState(null)
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [teacherClass, setTeacherClass] = useState([]);
  const defaultClass = [
    {
      label: "หลักสูตรกำหนดเอง",
      value: false
    },
    {
      label: "หลักสูตรพื้นฐาน",
      value: true
    }
  ]
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [imageBlobUrl, setImageBlobUrl] = useState(null);

  const handleClose = () => {
    setShowSidePanel(false);
    setSelectedCertificate(null);
    if (imageBlobUrl) {
      URL.revokeObjectURL(imageBlobUrl); // Clean up Blob URL
      setImageBlobUrl(null);
    }
  };


  const [formData, setFormData] = useState({
    id: "",
    start:'',
    end:'',
    cert_tempalte:'',
    teacher_sub:'',
    name:'',
    is_default: true
  });

  const isFormValid = () => {
    const {
      start,
      end,
      name,
    } = formData;
  // Check if required fields are filled
  if (!start || !end || !name) {
    showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน');
    return false;
  }

  // Ensure ready date is before expired date
  if (new Date(start) >= new Date(end)) {
    showErrorDialog('วันที่เริ่มต้นต้องน้อยกว่าวันหมดอายุ');
    return false;
  }

  // If all fields are valid
  return true;
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleDateChange = (date, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  const fetchDropdown = async () => {
    setLoading(true)
    try {
      const teachers = await getTeacherList()
      if(teachers){
        const user_id = user?.id;
        const teacherOptions = teachers.data
        .filter(c => c.user_id !== user_id) // Filter out the user with matching id
        .map(c => ({
          label: c.fullname,
          value: c.user_id
        }));
        setTeacherOptions(teacherOptions);
      }else{
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate('/classmanage');
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate('/classmanage');
        }
      });
    } finally {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await getClassroomByID(id);
        if (response) {
          setClassroomData(response)
          setFormData({
            id: response?.cla_id,
            start: response?.clc_startdate,
            end: response?.clc_enddate,
            cert_tempalte: response?.cert_tempalte,
            teacher_sub: response?.sub,
            name: response?.cla_name,
            is_default: response?.is_default
          })
        }else{
          showErrorDialog('ไม่พบข้อมูล Classroom').then( async (result) => {
                if (result.isConfirmed || result.isDismissed) {
                    navigate('/classmanage');
                }
              });
        }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/classmanage");
        }
      });
    } finally {
      setLoading(false)
    }
  };

  const createClassroomData = async () => {
    if (!isFormValid()) {
      return;
    }

    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      formData.tea_id = user?.id
      const response = await create(formData);
      if (response) {
        showSuccessDialog('เพิ่มข้อมูลสำเร็จ')
        navigate("/classmanage");
      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/classmanage");
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/classmanage");
        }
      });
    } finally {
      setLoading(false)
    }
  }

  const updateClassroomData = async (id) => {
    if (!isFormValid()) {
      return;
    }

    try {

      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      formData.tea_id = user?.id
      const response = await edit(id,formData);
      if (response) {
        showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
        navigate("/classmanage");
      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/classmanage");
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/classmanage");
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const method = urlParams.get("method");
    fetchDropdown()
    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate("/classmanage");
    }

    if (method === "view" || method === "edit") {
      fetchData();
    }

    if (method === "view") {
      setIsView(true);
    }

    setMethod(method);
  }, []);

  return (
    <>
    {isLoading ? (
      <LoadingComponent/>
    ) : (
      <div className="container-fluid">
        <div className="box-white">
          <div className="mt-3">
            <h4 className="px-3 mb-2">ห้องสอน</h4>
              <h5 className="px-3 mb-2 text-header">จัดการห้องสอน {classroomData ? `| ${classroomData?.cla_name}`:``} </h5>

              {/* <Row className="mt-3 px-5">
                <Col xs={'auto'} md={1} className="align-self-center">
                    <span className="fontLight font-size03 colorGray01 wh-sp">
                    หลักสูตร 
                    </span>
                  </Col>
                  <Col xs={12} md={4}>

                  <Select
                    isDisabled={method !== 'create'}
                    name="group"
                    value={defaultClass.find(
                      (option) => option.value === formData.is_default
                    )}
                    options={defaultClass}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        is_default: e.value,
                      }));
                    }}
                    placeholder="Please select"
                  />
                    {formData.is_default && (
                      <span className="colorBlue01 fontLight font-size03 colorGray01 wh-sp">
                        ใช้หลักสูตรพื้นฐาน จะไม่สามารถแก้ไขเนื้อหาบทเรียนได้
                      </span>
                    )}
                  </Col>

            </Row> */}
            <Row className="mt-3 px-5">

              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อห้องสอน 
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.name}
                  name="name"
                  id="name"
                  onChange={handleChange}

                />
              </Col>

              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                ผู้ช่วยสอน 
                </span>
              </Col>
              <Col xs={12} md={4}>
              <Select
                    isDisabled={isView}
                    name="subteachergroup"
                    value={teacherOptions.find(
                      (option) => option.value === formData.teacher_sub
                    )}
                    options={teacherOptions}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        teacher_sub: e.value,
                      }));
                    }}
                    placeholder="Please select"
                  />
              </Col>
            </Row>

            {/* 2 */}
            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                วันที่เริ่มต้น 
                </span>
              </Col>
              <Col xs={12} md={4}>
              <ReactDatePicker
                    style={{ display: "block" }}
                    selected={formData.start}
                    onChange={(date) => handleDateChange(date, "start")}
                    // disabled={method === 'view'}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="วัน/เดือน/ปี"
                    className="inp-login fontLight font-size03"
                  />
              </Col>

              <Col xs={'auto'} md={1}className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                วันสิ้นสุด 
                </span>
              </Col>
              <Col xs={12} md={4}>
              <ReactDatePicker
                  style={{ display: "block" }}
                  selected={formData.end}
                  onChange={(date) => handleDateChange(date, "end")}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="วัน/เดือน/ปี"
                  className="inp-login fontLight font-size03"
                />
              </Col>
            </Row>

            {/* 3 */}

            <Row className="mt-3 px-5">
              {/* <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                ผู้ช่วยสอน 
                </span>
              </Col>
              <Col xs={12} md={4}>
                <DropdownSearch
                  isDisabled={isView}
                  value={teacherClass}
                  handler={(e) => {
                    setClassroomType([e]);
                    setFormData((prev) => ({
                      ...prev,
                      teacher_sub: e.value,
                    }));
                  }}
                  options={teacherOptions} // Pass the mapped options here
                />
              </Col> */}

              {/* <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                รูปแบบใบรับรอง 
                </span>
              </Col>
              <Col xs={12} md={4}>
                <DropdownSearch
                  isDisabled={isView}
                  value={classcertificate}
                  handler={(e) => {
                    setClasscertificate([e]);
                    setFormData((prev) => ({
                      ...prev,
                      cert_tempalte: e.value,
                    }));
                  }}
                  options={certificateOptions} // Pass the mapped options here
                />
                <button className="btn-save m-1">View</button>
              </Col> */}
              
            </Row>


            <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate("/classmanage");
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createClassroomData()
                        : updateClassroomData(classroomData?.cla_id);
                    }}
                    // disabled={!isFormValid()}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>

          {/* Side Panel */}
          {selectedCertificate && (
            <div className={`side-panel ${showSidePanel ? "open" : ""}`}>
              <div className="side-panel-header">
                <h3>ใบรับรอง</h3>
                <Button
                  variant="link"
                  onClick={handleClose}
                  className="close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
              </div>
              <div className="side-panel-content">
                <h4>ID: {selectedCertificate.thc_id}</h4>
                <h5>{selectedCertificate.thc_thumbnail}</h5>
                {imageBlobUrl ? (
                  <img
                    src={imageBlobUrl}
                    alt="Certificate"
                    style={{ width: "100%", height: "auto" }}
                  />
                ) : (
                  <p>Loading image...</p>
                )}
              </div>
            </div>
          )}

        </div>
      </div>
    )}
  </>
  );
};

export default TeacherClassManageAction;
